.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    width: 494px;
    height: 274px;
    text-align: center;
    align-items: center;
  }
  .popupHeader{
    font-family: Lexend;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
  .inputs{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .input{
    width: 212.97px;
    height: 36px;
    border-radius: 10px;
    background-color: rgba(245, 242, 240, 1);
    margin-bottom: 5px;
    font-family: Lexend;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(23, 48, 74, 0.5);
    border: none;
    padding-left: 5px;

  }

  .popupBtn{
    width: 224px;
    height: 38px;
    border-radius: 5px;
    background-color: rgba(140, 199, 64, 1);
    font-family: Lexend;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
    align-items: center;
    text-align: center;
    border: none;
    margin-top: 10px;
    cursor: pointer;

  }
  
  .popupIcon{
    width: 38px;
    height: 38px;
    color: rgba(23, 48, 74, 1);
    cursor: pointer;
  }

  @media (max-width: 746px) {
    .popup-content{
      width: 100%;
      height: fit-content;
    }
    .inputs{
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .input{
      width: 90%;
    }
    
  }