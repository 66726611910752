.uploadScreen{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.uploadBody{
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: center;
    padding-top: 100px;
}
.uploadCard{
    width: 720px;
    height: 420px;
    border-radius: 5px;
    background-color: rgba(43, 89, 137, 0.1);
    align-items: center;
    text-align: center;
    margin-left: 360px;
    font-family: Lexend;

}

.uploadTitle{
    font-family: Lexend;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: center;
    color: #17304A;
    padding-top: 20px;

}

.uploadImage{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly ;
    width:612px;
    height: 84px;
    border-radius: 5px;
    background-color: rgba(43, 89, 137, 0.1);
    align-items: center;
    text-align: center;
    margin-left: 45px;
    margin-top: 10px;
}

.uploadDescription{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width:612px;
    height: 84px;
    border-radius: 5px;
    background-color: rgba(43, 89, 137, 0.1);
    align-items: center;
    text-align: center;
    margin-left: 45px;
    margin-top: 15px;

}

.uploadButton{
    align-items: center;
    align-self: center;
    text-align: center;
    width: 226px;
    height: 36px;
    border-radius: 5px;
    background-color: #8CC740;
    color: white;
    cursor: pointer;
    font-family: Lexend;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 10px;

}

.ImageWord{
    font-family: Lexend;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    justify-content: space-evenly;
    color: #17304A;

}

.uploadImageBtn{
    width: 195px;
    height: 31px;
    border-radius: 5px;
    background-color: #17304A;
    color: white;
    font-family: Lexend;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    border: none;

}

.uploadTextarea{
    width: 327px;
    height: 47px;
    border-radius: 5px;
    background-color: rgba(43, 89, 137, 0.1);
    font-family: Lexend;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    border: none;
    color: rgba(23, 48, 74, 0.5);
    padding: 10px;

}

@media (max-width: 746px) {
    .uploadCard{
        width: 100%;
        height: fit-content;
        margin: 0px;
        align-items: center;
        text-align: center;
    }
    .uploadImage{
        width: 100%;
        margin: 5px;
        align-items: center;
        text-align: center;
    }
    .uploadDescription{
        width: 100%;
        margin: 5px;
        text-align: center;

    }
    .uploadTextarea{
        width: 80%;
    }

}