.aboutuscontainer{
    width: 100%;
    height: 100%
}

.aboutushead{
    padding-top: 100px;
    text-align: center;
    align-items: center;
    font-family: Lexend;
    justify-content: center;
}
.Fundiapp{
    width: 100%;
    height: fit-content;
    align-items: center;
    text-align: center;
    font-family: Lexend;
}
.rekebisharepair{
    width: 100%;
    height: fit-content;
    align-items: center;
    text-align: center;
    font-family: Lexend;
    background-color: #2B5989;
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;
}
.dotdot{
    width: 100%;
    height: fit-content;
    align-items: center;
    text-align: center;
    font-family: Lexend;

}
.findhardware{
    width: 100%;
    height: fit-content;
    align-items: center;
    text-align: center;
    font-family: Lexend;
    background-color: #2B5989;
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;

}

.paddingtext{
    padding-left: 50px;
    padding-right: 50px;
}